code {
  display: block;
  background-color: #f4f4f4;
  padding: 0.5em;
  border-radius: 0.25em;
  font-family: Consolas, Menlo, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 90%;
  line-height: 1.5;
  max-width: 60vw;
  overflow-wrap: break-word;
  margin: 0.4rem 0;
}

pre code {
  display: block;
  white-space: pre-wrap;
}
